import { createSelector } from "reselect";
import { createGetAtKey } from "@zedoc/selectors";
import matchToken from "../../utils/matchToken";

export const selectLocation = createGetAtKey("router.location");

export const selectTokenFromHash = createSelector(
  selectLocation,
  (location) => {
    if (location && location.hash) {
      return matchToken(location.hash);
    }
    return {};
  }
);

export const selectSessionJwt = createSelector(
  createGetAtKey("token.sessionJwt"),
  // NOTE: This is used for backwards compatibility because we don't want to
  //       accidentally logout users who already have a sessionJwt stored
  //       under the old key.
  createGetAtKey("token.value"),
  (sessionJwt, value) => {
    return sessionJwt || value;
  }
);

export const selectRefreshJwt = createGetAtKey("token.refreshJwt");

export const selectAuthJwt = createGetAtKey("token.authJwt");

export const selectSecret = createGetAtKey("token.secret");

export const selectStartingSurvey = createGetAtKey("token.startingSurvey");

// NOTE: On initial load, the token may still be in the hash so neither of the
//       above selectors will return a value. We consider this state
//       "authenticating" because we want to prevent the consequences of
//       logging out a user who accidentally landed on LoggedInRoute.
export const selectIsAuthenticating = createSelector(
  selectTokenFromHash,
  selectSessionJwt,
  selectRefreshJwt,
  selectAuthJwt,
  selectStartingSurvey,
  (hashToken, sessionJwt, refreshJwt, authJwt, startingSurvey) =>
    !!hashToken.token ||
    !!startingSurvey ||
    (!(sessionJwt || refreshJwt) && !!authJwt)
);

export const selectIsLoggedIn = createSelector(
  selectSessionJwt,
  selectRefreshJwt,
  (sessionJwt, refreshJwt) => !!(sessionJwt || refreshJwt)
);

export const selectLastActivityTs = createGetAtKey("token.lastActivityTs");
